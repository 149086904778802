import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

const Footer = () => {
  return (
    <footer>
      <Container>
        <Col>
          <center>
            <Row>
              <hr />
              <center>
                <h5>
                  Connecting ... People ... to ... home-chefs ... & ...
                  Food-trucks !
                </h5>
              </center>
              <hr />
              <br />
              <center>
                <Row>
                  {/* <Col>
                    <a
                      href='https://www.instagram.com/aveek.codes/'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Contact: Aveek Roy
                    </a>
                  </Col> */}
                  <center>Copyright &copy; Tasty Trails @2024</center>
                  {/* <Col>
                    <a
                      href='https://www.youtube.com/watch?v=WTvucxJVc24&list=PL8CULJWGXU2mD9ljYQKZjpK7xZJcc6TMW'
                      target='_blank'
                      rel='noreferrer'
                    >
                      YouTube Tutorials
                    </a>
                  </Col> */}
                </Row>
              </center>
            </Row>
          </center>
        </Col>
      </Container>
      <br />
    </footer>
  )
}

export default Footer
