import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Button, Alert } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Product from '../components/Product'
import Message from '../components/Message'
import Loader from '../components/Loader'
import ProductCarousel from '../components/ProductCarousel'
import SearchAndFilter from './SearchAndFilter'
import { listProducts } from '../store/actions/productActions.js'
import tastytrails from './tastytrails.png'

const HomeScreen = () => {
  const dispatch = useDispatch()
  const [loadAllProducts, setLoadAllProducts] = useState(true)
  const [showSearch, setShowSearch] = useState(false)
  // Used for displaying the state of the application in the frontend
  const productList = useSelector((state) => state.productList)
  const { loading, error, products } = productList

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const handleClose = () => {
    setShowSearch(false)
    setLoadAllProducts(true)
  }

  useEffect(() => {
    // Fetches it from the database using redux actions
    dispatch(listProducts())
  }, [dispatch])

  return (
    <>
      <ProductCarousel />
      {products?.length > 0 ? (
        <h5>
          <br />
          <center>
            <center>
              <br />
              <Message>
                {moment().format('dddd, MMMM Do, YYYY')} - Choose from Latest
                Cuisines!
              </Message>
            </center>
            <br />
            <div style={{ display: 'grid', gridTemplate: '1fr/3fr 1fr' }}>
              <div>
                For Searching & filtering HomeChefs & FoodTrucks, using "zip
                code"{' '}
                {userInfo ? (
                  <Link to='/cheflist'>CLICK HERE!</Link>
                ) : (
                  <Link to='/login'>LOGIN!</Link>
                )}
              </div>{' '}
              <div>
                <Button variant='success' onClick={() => setShowSearch(true)}>
                  Search & Filter
                </Button>
                {showSearch && (
                  <Button variant='danger' onClick={handleClose}>
                    Close
                  </Button>
                )}
              </div>
            </div>
          </center>
        </h5>
      ) : (
        <div>
          <center>
            <img
              src={tastytrails}
              alt='tastytrails'
              style={{
                height: '40vh',
                width: '65vw',
                border: 'solid 2px black',
              }}
            />
          </center>
          <br />
          <Message variant='info'>
            <center>
              {moment().format('dddd, MMMM Do, YYYY')} - No Latest Cuisines Yet
              - Please check back later!
            </center>
          </Message>
          <br />
          <br />
        </div>
      )}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <div>
          <br />
          {products?.length > 0 && showSearch && (
            <SearchAndFilter
              products={products}
              setLoadAllProducts={setLoadAllProducts}
            />
          )}
          <br />
          <Row>
            {loadAllProducts &&
              products.map((product) => (
                <Col key={product._id} sm={12} md={6} lg={4} xl={3}>
                  <Product product={product} />
                </Col>
              ))}
          </Row>
        </div>
      )}
    </>
  )
}

export default HomeScreen
