import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Table, Form, Button, Row, Col } from 'react-bootstrap'
import { useNavigate } from 'react-router'
import { LinkContainer } from 'react-router-bootstrap'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { listChefOrders } from '../store/actions/orderActions'
import dayjs from 'dayjs'

const MyChefOrders = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const reload = () => {
    window.location.reload()
  }

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const orderChefList = useSelector((state) => state.orderChefList)
  const { loading: loadingOrders, error: errorOrders, orders } = orderChefList

  // Good logic - Aveek Roy - 4th March 2023
  const orderCompleted = (ordr) => {
    var orderStat = 0
    var countItems = 0
    countItems = ordr.orderItems.filter((item) => item.chefId === userInfo._id)

    ordr.orderItems
      .filter((item) => item.chefId === userInfo._id)
      .map((result) => {
        if (
          result.isPaid &&
          result.isPickedUp &&
          result.orderStatus === 'Ready'
        ) {
          orderStat = orderStat + 1
        }
        return orderStat
      })

    if (countItems.length === orderStat) {
      return <i className='fas fa-check' style={{ color: 'green' }}></i>
    } else {
      return <i className='fas fa-times' style={{ color: 'red' }}></i>
    }
  }

  useEffect(() => {
    if (userInfo && userInfo.isChef) {
      dispatch(listChefOrders(userInfo._id))
    } else {
      navigate('/login')
    }
  }, [dispatch, navigate, userInfo])

  return (
    <Col md={12}>
      <h2>My Orders</h2>
      <center>
        <Button variant='success' onClick={reload}>
          Get my latest orders
        </Button>
      </center>
      <br />
      {loadingOrders ? (
        <Loader />
      ) : errorOrders ? (
        <Message variant='danger'>{errorOrders}</Message>
      ) : (
        <Table striped bordered hover responsive className='table-sm'>
          <thead>
            <tr>
              <th>ORDER ID</th>
              <th>ORDER DATE</th>
              <th>ITEMS</th>
              <th>AMOUNT</th>
              <th>
                <center>ORDER COMPLETED?</center>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {orders.reverse().map((order) => (
              <tr key={order._id}>
                <td>{order._id}</td>
                <td>
                  {dayjs(order.createdAt.substring(0, 10)).format('MM/DD/YYYY')}
                </td>
                <td>
                  {
                    order.orderItems.filter(
                      (item) => item.chefId === userInfo._id
                    ).length
                  }
                </td>
                <td>
                  {order.orderItems
                    .filter((item) => item.chefId === userInfo._id)
                    .reduce((acc, item) => acc + item.qty * item.price, 0)
                    .toFixed(2)}
                </td>
                <td>
                  <center>{orderCompleted(order)}</center>
                </td>
                <td>
                  <LinkContainer to={`/order/chef/${order._id}`}>
                    <Button className='btn-sm' variant='light'>
                      Details
                    </Button>
                  </LinkContainer>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Col>
  )
}

export default MyChefOrders
