import React, { useState, useEffect } from 'react'
import { Table, Form, Button, Row, Col } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { getUserDetails, updateUserProfile } from '../store/actions/userActions'
import { listMyOrders } from '../store/actions/orderActions'
import dayjs from 'dayjs'

const ProfileScreen = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isChef, setIsChef] = useState(false)
  const [message, setMessage] = useState(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const userDetails = useSelector((state) => state.userDetails)
  const { loading, error, user } = userDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
  const { success } = userUpdateProfile

  const orderMyList = useSelector((state) => state.orderMyList)
  const { loading: loadingOrders, error: errorOrders, orders } = orderMyList

  const orderCompleted = (ordr) => {
    var orderStat = 0
    var countItems = 0
    countItems = ordr.orderItems
    ordr.orderItems.map((result) => {
      if (
        result.isPaid &&
        result.isPickedUp &&
        result.orderStatus === 'Ready'
      ) {
        orderStat = orderStat + 1
      }
      return orderStat
    })

    if (countItems.length === orderStat) {
      return <i className='fas fa-check' style={{ color: 'green' }}></i>
    } else {
      return <i className='fas fa-times' style={{ color: 'red' }}></i>
    }
  }

  const reload = () => {
    window.location.reload()
  }

  useEffect(() => {
    if (!userInfo) {
      navigate('/login')
    } else {
      if (!user.name) {
        dispatch(getUserDetails('profile'))
        dispatch(listMyOrders())
      } else {
        setName(user.name)
        setEmail(user.email)
        setPhone(user.phone)
        setIsChef(user.isChef)
      }
    }
  }, [navigate, userInfo, dispatch, user])

  const submitHandler = (e) => {
    e.preventDefault()
    //DISPATCH Register
    if (password !== confirmPassword) {
      setMessage('Passwords do not match')
    } else {
      // DISPATCH UPDATE PROFILE
      dispatch(
        updateUserProfile({ id: user._id, name, email, phone, password })
      )
    }
  }
  return (
    <Row>
      <Col md={3}>
        <h2>Profile Details</h2>
        {message && <Message variant='danger'>{message}</Message>}
        {error && <Message variant='danger'>{error}</Message>}
        {success && <Message variant='success'>Profile Updated</Message>}
        {loading && <Loader />}
        <br />
        <Form onSubmit={submitHandler}>
          <Form.Group controlId='name'>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type='name'
              placeholder='Enter Name'
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId='email'>
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type='email'
              placeholder='Enter Email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId='phone'>
            <Form.Label>Contact Number</Form.Label>
            <Form.Control
              type='phone'
              placeholder='Enter Contact Number'
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId='password'>
            <Form.Label>Password</Form.Label>
            <Form.Control
              type='password'
              placeholder='Enter Password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId='confirmPassword'>
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type='password'
              placeholder='Confirm Password'
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <br />
          <Button type='submit' variant='primary'>
            Update
          </Button>
        </Form>
      </Col>
      <Col md={9}>
        <h2>My Orders</h2>
        <center>
          <Button variant='success' onClick={reload}>
            Get my latest placed orders
          </Button>
        </center>
        <br />
        {loadingOrders ? (
          <Loader />
        ) : errorOrders ? (
          <Message variant='danger'>{errorOrders}</Message>
        ) : (
          <Table striped bordered hover responsive className='table-sm'>
            <thead>
              <tr>
                <th>ORDER ID</th>
                <th>ORDER DATE</th>
                <th>AMOUNT</th>
                <th>ORDER COMPLETED?</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {orders.reverse().map((order) => (
                <tr key={order._id}>
                  <td>{order._id}</td>
                  <td>
                    {dayjs(order.createdAt.substring(0, 10)).format(
                      'MM/DD/YYYY'
                    )}
                  </td>
                  <td>${order.totalPrice}</td>
                  <td>
                    <center>{orderCompleted(order)}</center>
                  </td>
                  <td>
                    <LinkContainer to={`/order/${order._id}`}>
                      <Button className='btn-sm' variant='light'>
                        Details
                      </Button>
                    </LinkContainer>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Col>
    </Row>
  )
}

export default ProfileScreen
