// PRODUCT Starts
export const PRODUCT_LIST_REQUEST = 'PRODUCT_LIST_REQUEST'
export const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS'
export const PRODUCT_LIST_FAIL = 'PRODUCT_LIST_FAIL'

export const PRODUCT_LIST_CHEF_REQUEST = 'PRODUCT_LIST_CHEF_REQUEST'
export const PRODUCT_LIST_CHEF_SUCCESS = 'PRODUCT_LIST_CHEF_SUCCESS'
export const PRODUCT_LIST_CHEF_FAIL = 'PRODUCT_LIST_CHEF_FAIL'
export const PRODUCT_LIST_CHEF_RESET = 'PRODUCT_LIST_CHEF_RESET'

export const PRODUCT_DETAILS_REQUEST = 'PRODUCT_DETAILS_REQUEST'
export const PRODUCT_DETAILS_SUCCESS = 'PRODUCT_DETAILS_SUCCESS'
export const PRODUCT_DETAILS_FAIL = 'PRODUCT_DETAILS_FAIL'

export const PRODUCT_DELETE_REQUEST = 'PRODUCT_DELETE_REQUEST'
export const PRODUCT_DELETE_SUCCESS = 'PRODUCT_DELETE_SUCCESS'
export const PRODUCT_DELETE_FAIL = 'PRODUCT_DELETE_FAIL'

export const PRODUCT_CREATE_REQUEST = 'PRODUCT_CREATE_REQUEST'
export const PRODUCT_CREATE_SUCCESS = 'PRODUCT_CREATE_SUCCESS'
export const PRODUCT_CREATE_FAIL = 'PRODUCT_CREATE_FAIL'
export const PRODUCT_CREATE_RESET = 'PRODUCT_CREATE_RESET'

export const PRODUCT_UPDATE_REQUEST = 'PRODUCT_UPDATE_REQUEST'
export const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS'
export const PRODUCT_UPDATE_FAIL = 'PRODUCT_UPDATE_FAIL'
export const PRODUCT_UPDATE_RESET = 'PRODUCT_UPDATE_RESET'

export const PRODUCT_CREATE_REVIEW_REQUEST = 'PRODUCT_CREATE_REVIEW_REQUEST'
export const PRODUCT_CREATE_REVIEW_SUCCESS = 'PRODUCT_CREATE_REVIEW_SUCCESS'
export const PRODUCT_CREATE_REVIEW_FAIL = 'PRODUCT_CREATE_REVIEW_FAIL'
export const PRODUCT_CREATE_REVIEW_RESET = 'PRODUCT_CREATE_REVIEW_RESET'

export const PRODUCT_TOP_REQUEST = 'PRODUCT_TOP_REQUEST'
export const PRODUCT_TOP_SUCCESS = 'PRODUCT_TOP_SUCCESS'
export const PRODUCT_TOP_FAIL = 'PRODUCT_TOP_FAIL'
// PRODUCT Ends

// CART Starts
export const CART_ADD_ITEM = 'CART_ADD_ITEM'
export const CART_REMOVE_ITEM = 'CART_REMOVE_ITEM'
export const CART_SAVE_SHIPPING_ADDRESS = 'CART_SAVE_SHIPPING_ADDRESS'
export const CART_SAVE_PAYMENT_METHOD = 'CART_SAVE_PAYMENT_METHOD'
export const CART_RESET = 'CART_RESET'
// CART Ends

// USER Starts
export const USER_LOGIN_REQUEST = 'USER_LOGIN_REQUEST'
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const USER_LOGIN_FAIL = 'USER_LOGIN_FAIL'
export const USER_LOGOUT = 'USER_LOGOUT'

export const USER_REGISTER_REQUEST = 'USER_REGISTER_REQUEST'
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS'
export const USER_REGISTER_FAIL = 'USER_REGISTER_FAIL'

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'
export const USER_DETAILS_RESET = 'USER_DETAILS_RESET'

export const USER_CHEF_DETAILS_GUEST_REQUEST = 'USER_CHEF_DETAILS_GUEST_REQUEST'
export const USER_CHEF_DETAILS_GUEST_SUCCESS = 'USER_CHEF_DETAILS_GUEST_SUCCESS'
export const USER_CHEF_DETAILS_GUEST_FAIL = 'USER_CHEF_DETAILS_GUEST_FAIL'
export const USER_CHEF_DETAILS_GUEST_RESET = 'USER_CHEF_DETAILS_GUEST_RESET'

export const USER_UPDATE_PROFILE_REQUEST = 'USER_UPDATE_PROFILE_REQUEST'
export const USER_UPDATE_PROFILE_SUCCESS = 'USER_UPDATE_PROFILE_SUCCESS'
export const USER_UPDATE_PROFILE_FAIL = 'USER_UPDATE_PROFILE_FAIL'
export const USER_UPDATE_PROFILE_RESET = 'USER_UPDATE_PROFILE_RESET'

export const USER_LIST_REQUEST = 'USER_LIST_REQUEST'
export const USER_LIST_SUCCESS = 'USER_LIST_SUCCESS'
export const USER_LIST_FAIL = 'USER_LIST_FAIL'
export const USER_LIST_RESET = 'USER_LIST_RESET'

export const USER_DELETE_REQUEST = 'USER_DELETE_REQUEST'
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL'

export const USER_UPDATE_REQUEST = 'USER_UPDATE_REQUEST'
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL'
export const USER_UPDATE_RESET = 'USER_UPDATE_RESET'
// USER Ends

// ORDER Starts
export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST'
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS'
export const ORDER_CREATE_FAIL = 'ORDER_CREATE_FAIL'

export const ORDER_GUEST_CREATE_REQUEST = 'ORDER_GUEST_CREATE_REQUEST'
export const ORDER_GUEST_CREATE_SUCCESS = 'ORDER_GUEST_CREATE_SUCCESS'
export const ORDER_GUEST_CREATE_FAIL = 'ORDER_GUEST_CREATE_FAIL'

export const ORDER_DETAILS_REQUEST = 'ORDER_DETAILS_REQUEST'
export const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS'
export const ORDER_DETAILS_FAIL = 'ORDER_DETAILS_FAIL'

export const ORDER_GUEST_DETAILS_REQUEST = 'ORDER_GUEST_DETAILS_REQUEST'
export const ORDER_GUEST_DETAILS_SUCCESS = 'ORDER_GUEST_DETAILS_SUCCESS'
export const ORDER_GUEST_DETAILS_FAIL = 'ORDER_GUEST_DETAILS_FAIL'
export const ORDER_GUEST_DETAILS_RESET = 'ORDER_GUEST_DETAILS_RESET'

export const ORDER_CHEF_DETAILS_REQUEST = 'ORDER_CHEF_DETAILS_REQUEST'
export const ORDER_CHEF_DETAILS_SUCCESS = 'ORDER_CHEF_DETAILS_SUCCESS'
export const ORDER_CHEF_DETAILS_FAIL = 'ORDER_CHEF_DETAILS_FAIL'
export const ORDER_CHEF_DETAILS_RESET = 'ORDER_CHEF_DETAILS_RESET'

export const ORDER_PAY_REQUEST = 'ORDER_PAY_REQUEST'
export const ORDER_PAY_SUCCESS = 'ORDER_PAY_SUCCESS'
export const ORDER_PAY_FAIL = 'ORDER_PAY_FAIL'
export const ORDER_PAY_RESET = 'ORDER_PAY_RESET'

export const ORDER_MY_LIST_REQUEST = 'ORDER_MY_LIST_REQUEST'
export const ORDER_MY_LIST_SUCCESS = 'ORDER_MY_LIST_SUCCESS'
export const ORDER_MY_LIST_FAIL = 'ORDER_MY_LIST_FAIL'
export const ORDER_MY_LIST_RESET = 'ORDER_MY_LIST_RESET'

export const ORDER_LIST_REQUEST = 'ORDER_LIST_REQUEST'
export const ORDER_LIST_SUCCESS = 'ORDER_LIST_SUCCESS'
export const ORDER_LIST_FAIL = 'ORDER_LIST_FAIL'

export const ORDER_CHEF_LIST_REQUEST = 'ORDER_CHEF_LIST_REQUEST'
export const ORDER_CHEF_LIST_SUCCESS = 'ORDER_CHEF_LIST_SUCCESS'
export const ORDER_CHEF_LIST_FAIL = 'ORDER_CHEF_LIST_FAIL'

export const ORDER_DELIVER_REQUEST = 'ORDER_DELIVER_REQUEST'
export const ORDER_DELIVER_SUCCESS = 'ORDER_DELIVER_SUCCESS'
export const ORDER_DELIVER_FAIL = 'ORDER_DELIVER_FAIL'
export const ORDER_DELIVER_RESET = 'ORDER_DELIVER_RESET'

export const ORDER_STATUS_REQUEST = 'ORDER_STATUS_REQUEST'
export const ORDER_STATUS_SUCCESS = 'ORDER_STATUS_SUCCESS'
export const ORDER_STATUS_FAIL = 'ORDER_STATUS_FAIL'
export const ORDER_STATUS_RESET = 'ORDER_STATUS_RESET'

export const ORDER_DELETE_REQUEST = 'ORDER_DELETE_REQUEST'
export const ORDER_DELETE_SUCCESS = 'ORDER_DELETE_SUCCESS'
export const ORDER_DELETE_FAIL = 'ORDER_DELETE_FAIL'

// ORDER Ends
