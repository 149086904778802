import React, { useState } from 'react'
import { Form, Button, InputGroup, Row, Col } from 'react-bootstrap'
import Product from '../components/Product'
import { foodCategory } from './selectOptions'
import Message from '../components/Message'

const SearchAndFilter = ({ products, setLoadAllProducts }) => {
  console.log('Aveek products', products)

  const [filteredProduct, setFilteredProduct] = useState(products)
  const [isSearchEnabled, setIsSearchEnabled] = useState(false)
  const [foodSearchInput, setFoodSearchInput] = useState('')
  const [selectCategory, setSelectCategory] = useState('')
  const [minAmt, setMinAmt] = useState('')
  const [maxAmt, setMaxAmt] = useState('')
  const handleSearch = () => {
    setIsSearchEnabled(true)
    setLoadAllProducts(false)
    setFilteredProduct(
      products.filter((fooditem) => fooditem.name.includes(foodSearchInput))
    )
  }

  const handleSelect = (e) => {
    setSelectCategory(e.target.value)
    setIsSearchEnabled(true)
    setLoadAllProducts(false)
    setFilteredProduct(
      products.filter((fooditem) => fooditem.category === e.target.value)
    )
  }

  const handlePriceRange = (e) => {
    setIsSearchEnabled(true)
    setLoadAllProducts(false)
    setFilteredProduct(
      products.filter(
        (fooditem) => fooditem.price >= minAmt && fooditem.price <= maxAmt
      )
    )
  }

  const resetFilters = () => {
    setFoodSearchInput('')
    setSelectCategory('')
    setMinAmt('')
    setMaxAmt('')
    setFilteredProduct(products)
  }
  return (
    <div>
      <div
        style={{
          display: 'grid',
          gridTemplate: '1fr/1fr 1fr',
          gap: '30px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            border: 'solid 2px grey',
          }}
        >
          <div style={{ padding: '15px' }}>
            <center>
              <Form.Label>SEARCH</Form.Label>
            </center>
            <InputGroup className='mb-3'>
              <Form.Control
                placeholder='Type Food Item...'
                onChange={(e) => setFoodSearchInput(e.target.value)}
                value={foodSearchInput}
              />
              <Button variant='dark' id='button-addon2' onClick={handleSearch}>
                Search
              </Button>
            </InputGroup>
          </div>
          <div style={{ padding: '15px' }}>
            <center>
              <Form.Label>CATEGORY </Form.Label>
            </center>

            <Form.Select
              aria-label='Default select example'
              value={selectCategory}
              onChange={(e) => handleSelect(e)}
            >
              {foodCategory.map((category) => (
                <option value={category}>{category}</option>
              ))}
            </Form.Select>
          </div>{' '}
        </div>
        <div style={{ border: 'solid 2px grey', padding: '15px' }}>
          <div
            style={{
              display: 'flex',
            }}
          >
            <div style={{ padding: '5px' }}>
              <Form.Label>MIN</Form.Label>
              <InputGroup className='mb-3'>
                <InputGroup.Text>$</InputGroup.Text>
                <Form.Control
                  onChange={(e) => setMinAmt(e.target.value)}
                  value={minAmt}
                />
              </InputGroup>
            </div>
            <div style={{ padding: '5px' }}>
              <Form.Label>MAX</Form.Label>
              <InputGroup className='mb-3'>
                <InputGroup.Text>$</InputGroup.Text>
                <Form.Control
                  onChange={(e) => setMaxAmt(e.target.value)}
                  value={maxAmt}
                />
              </InputGroup>
            </div>
          </div>
          <center>
            <Button style={{ marginTop: '5px' }} onClick={handlePriceRange}>
              Search
            </Button>
          </center>
        </div>
      </div>
      <br />
      <div>
        <center>
          <Button variant='info' onClick={resetFilters}>
            Reset Filters
          </Button>{' '}
        </center>
      </div>
      <Row>
        {isSearchEnabled &&
          filteredProduct.map((product) => (
            <Col key={product._id} sm={12} md={6} lg={4} xl={3}>
              <Product product={product} />
            </Col>
          ))}
      </Row>
    </div>
  )
}

export default SearchAndFilter
