import Footer from './components/Footer'
import Header from './components/Header'
import { Container } from 'react-bootstrap'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import HomeScreen from './screens/HomeScreen'
import ProductScreen from './screens/ProductScreen'
import CartScreen from './screens/CartScreen'
import LoginScreen from './screens/LoginScreen'
import RegisterScreen from './screens/RegisterScreen'
import ProfileScreen from './screens/ProfileScreen'
import ProfileChefScreen from './chefscreens/ProfileChefScreen'
import ShippingScreen from './screens/ShippingScreen'
import PaymentScreen from './screens/PaymentScreen'
import PlaceOrderScreen from './screens/PlaceOrderScreen'
import OrderScreen from './screens/OrderScreen'
import OrderChefScreen from './chefscreens/OrderChefScreen'
import UserListScreen from './screens/UserListScreen'
import UserEditScreen from './screens/UserEditScreen'
import ProductListScreen from './screens/ProductListScreen'
import ProductListChefScreen from './chefscreens/ProductListChefScreen'
import ProductEditScreen from './screens/ProductEditScreen'
import ProductEditChefScreen from './chefscreens/ProductEditChefScreen'
import OrderListScreen from './screens/OrderListScreen'
import GuestCheckout from './screens/GuestCheckout'
import OrderGuestScreen from './screens/OrderGuestScreen'
import OrderSearch from './screens/OrderSearch'
import ChefList from './chefscreens/ChefList'
import LandingPage from './screens/LandingPage'
import MyChefOrders from './chefscreens/MyChefOrders'

const App = () => {
  return (
    <Router>
      <Header />
      <main className='py-3'>
        <Container>
          <Routes>
            <Route path='/' element={<LandingPage />} exact />
            <Route path='/mainpage' element={<HomeScreen />} exact />
            <Route path='/register' element={<RegisterScreen />} />
            <Route path='/search' element={<OrderSearch />} />
            <Route path='/guest' element={<GuestCheckout />} />
            <Route path='/cheflist' element={<ChefList />} />
            <Route path='/cheforders' element={<MyChefOrders />} />
            <Route path='/shipping' element={<ShippingScreen />} />
            <Route path='/payment' element={<PaymentScreen />} />
            <Route path='/placeholder' element={<PlaceOrderScreen />} />
            <Route path='/order/:id' element={<OrderScreen />} />
            <Route path='/order/guest/:id' element={<OrderGuestScreen />} />
            <Route path='/order/chef/:id' element={<OrderChefScreen />} />
            <Route path='/profile' element={<ProfileScreen />} />
            <Route path='/chefprofile' element={<ProfileChefScreen />} />
            <Route
              path='/chefitems/:id/product/:id'
              element={<ProductScreen />}
            />
            <Route path='/login' element={<LoginScreen />} />
            <Route path='/mainpage/product/:id' element={<ProductScreen />} />
            <Route path='/cart/' element={<CartScreen />} />
            <Route path='/admin/userlist' element={<UserListScreen />} />
            <Route path='/admin/user/:id/edit' element={<UserEditScreen />} />
            <Route path='/admin/productlist' element={<ProductListScreen />} />
            <Route
              path='/chef/productlist'
              element={<ProductListChefScreen />}
            />

            <Route
              path='/admin/product/:id/edit'
              element={<ProductEditScreen />}
            />
            <Route
              path='/chef/product/:id/edit'
              element={<ProductEditChefScreen />}
            />
            <Route path='/admin/orderlist' element={<OrderListScreen />} />
            <Route path='/cart/:id' element={<CartScreen />} />
          </Routes>
        </Container>
      </main>
      <br />
      <Footer />
    </Router>
  )
}

export default App
