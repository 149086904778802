import React from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router'
import { Button } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import signupbanner from './signupbanner.png'
import Message from '../components/Message'
import './landingpage.css'
import ReactPlayer from 'react-player'
import video from './tastytrailsvideo.mp4'

const LandingPage = () => {
  const navigate = useNavigate()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const handleClick = () => {
    navigate('/register')
  }

  return (
    <>
      <h6>
        <center>{moment().format('dddd, MMMM Do, YYYY')}</center>
      </h6>
      <center>
        <span className='kaushan-script-regular'>Tasty Trails</span>
      </center>
      <div className='player-wrapper' style={{ padding: '5px' }}>
        <center>
          <ReactPlayer
            className='react-player'
            url={video}
            width='80%'
            height='80%'
            controls={true}
            playing={true}
            loop={true}
            light={true}
          />
        </center>
      </div>
      <br />
      <center>
        {!userInfo || !userInfo.isChef ? (
          <div>
            <div>
              <img
                src={signupbanner}
                alt='signupbanner'
                onClick={handleClick}
                className='bannerimg'
              />
            </div>

            <Link
              variant='primary'
              to='/mainpage'
              className='btn btn-light my-1'
            >
              <Button variant='primary'>Latest Cuisines!</Button>
            </Link>
          </div>
        ) : (
          <center>
            <hr />
            <Message>You are Awesome, "{userInfo.name}" !</Message>
            <Message>Thanks for being a part of this journey !</Message>
            <hr />
          </center>
        )}
      </center>
    </>
  )
}

export default LandingPage
