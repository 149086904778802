import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useNavigate } from 'react-router'
import {
  Button,
  Row,
  Col,
  Image,
  Card,
  ListGroup,
  Toast,
  Alert,
} from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { getOrderDetails, deliverOrder } from '../store/actions/orderActions'
import { getUserDetails } from '../store/actions/userActions'
import {
  ORDER_PAY_RESET,
  ORDER_DELIVER_RESET,
  ORDER_STATUS_RESET,
} from '../store/types'

const OrderScreen = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const orderId = params.id
  const navigate = useNavigate()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const orderDetails = useSelector((state) => state.orderDetails)
  const { order, loading, error } = orderDetails

  const orderPay = useSelector((state) => state.orderPay)
  const { loading: loadingPay, success: successPay } = orderPay

  const orderDeliver = useSelector((state) => state.orderDeliver)
  const { loading: loadingDeliver, success: successDeliver } = orderDeliver

  const orderStatus = useSelector((state) => state.orderStatus)
  const { loading: loadingOrderStatus, success: successOrderStatus } =
    orderStatus

  useEffect(() => {
    if (!userInfo) {
      navigate('/login')
    }

    if (
      !order ||
      order._id !== orderId ||
      successPay ||
      successDeliver ||
      successOrderStatus
    ) {
      dispatch({ type: ORDER_PAY_RESET })
      dispatch({ type: ORDER_DELIVER_RESET })
      dispatch({ type: ORDER_STATUS_RESET })
      dispatch(getOrderDetails(orderId))
    }
  }, [
    dispatch,
    orderId,
    order,
    successPay,
    successDeliver,
    successOrderStatus,
    userInfo,
    navigate,
  ])

  const deliverHandler = (id) => {
    dispatch(deliverOrder(order, id))
  }

  const reload = () => {
    window.location.reload()
  }
  const [showA, setShowA] = useState(false)
  const toggleShowA = () => setShowA(!showA)

  const userDetails = useSelector((state) => state.userDetails)
  const {
    loading: loadingUserDetails,
    error: errorUserDetails,
    user,
  } = userDetails

  const chefDetails = (chefid) => {
    // dispatch an API call to get the details of the chef
    dispatch(getUserDetails(chefid))
    toggleShowA()
  }

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant='danger'>{error}</Message>
  ) : (
    <>
      <Row>
        <Link to='/profile'>GO BACK</Link>
        <h2>
          <Alert key='info' variant='info'>
            Order No - {order._id}
            <div style={{ textAlign: 'end' }}>
              <Button variant='success' onClick={reload}>
                CHECK LATEST ORDER STATUS
              </Button>
            </div>
          </Alert>
        </h2>
        <Col md={8}>
          <ListGroup variant='flush'>
            <ListGroup.Item>
              <h2>Your Details</h2>
              <strong>Name: </strong>
              {order.user.name}
              <br />
              <strong>Contact Number: </strong>
              {order.user.phone}
              <br />
              <strong>Email: </strong>
              <a href={`mailto:${order.user.email}`}>{order.user.email}</a>
              <br />
              <p>
                <strong>Address: </strong>
                {order.shippingAddress.address}, {order.shippingAddress.city},
                {order.shippingAddress.postalCode},{' '}
                {order.shippingAddress.country}
              </p>
            </ListGroup.Item>
            <ListGroup.Item>
              <h2>Payment Method</h2>
              <p>
                <strong>Method: </strong>
                {order.paymentMethod}
              </p>
            </ListGroup.Item>
            <ListGroup.Item>
              <h2>Order Items</h2>
              {order.orderItems.length === 0 ? (
                <Message>Your cart is empty</Message>
              ) : (
                <ListGroup variant='flush'>
                  {order.orderItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col md={1}>
                          <Image
                            src={item.image}
                            alt={item.name}
                            fluid
                            rounded
                          />
                        </Col>
                        <Col md={4}>
                          <Link to={`/mainpage/product/${item.product}`}>
                            {item.name}
                          </Link>
                        </Col>
                        {/* <Col md={4}>{item.chefId}</Col> */}
                        <Col>
                          {item.qty} x ${item.price} = ${item.qty * item.price}
                        </Col>
                        <Col md={4}>
                          {/* <Alert className='btn btn-sm' variant='success'>
                            Order Status: {item.orderStatus}
                          </Alert> */}
                          {item.orderStatus === 'Ready' ? (
                            <Message variant='success'>
                              <center>Order Status: {item.orderStatus}</center>
                            </Message>
                          ) : (
                            <Message variant='danger'>
                              <center>Order Status: {item.orderStatus}</center>
                            </Message>
                          )}
                        </Col>
                      </Row>

                      <Row>
                        <Button
                          variant='info'
                          className='mb-2'
                          onClick={() => chefDetails(item.chefId)}
                        >
                          click here for "Chef Details"
                        </Button>
                      </Row>
                      <br />

                      <Row>
                        <Col>
                          {!userInfo.isChef && item.orderStatus === 'Ready' && (
                            <Button
                              variant={item.isPickedUp ? 'dark' : 'success'}
                              onClick={() => deliverHandler(item._id)}
                              disabled={item.isPickedUp}
                            >
                              PICKED UP
                            </Button>
                          )}
                        </Col>
                        <Col>
                          {!userInfo.isChef && item.orderStatus === 'Ready' && (
                            <div>
                              {item.isPickedUp ? (
                                <Message variant='success'>
                                  <center>
                                    {/* {https://codesandbox.io/s/format-your-date-or-time-in-react-js-using-momentjs-ydcjw?from-embed} */}
                                    {moment(item.pickedAt).format('LLLL')}
                                  </center>
                                </Message>
                              ) : (
                                <Message variant='danger'>
                                  <center>Not Yet</center>
                                </Message>
                              )}
                            </div>
                          )}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={4}>
          <Card>
            <ListGroup variant='flush'>
              <ListGroup.Item>
                <h2>
                  <center>Order Summary</center>
                </h2>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <center>
                    <Col>Total Amount to Pay: ${order.itemsPrice}</Col>
                  </center>
                </Row>
              </ListGroup.Item>
              {loadingDeliver && <Loader />}
            </ListGroup>
          </Card>

          <br />
          <br />

          <Toast show={showA} onClose={toggleShowA}>
            <Toast.Header>
              <img className='rounded me-2' alt='' />
              <strong className='me-auto'>Chef Details</strong>
            </Toast.Header>
            <Toast.Body>
              <Alert key='primary' variant='primary'>
                <em>Name: </em>
                {user.name}
                <hr />
                <em>Contact Number: </em>
                {user.phone}
                <hr />
                <em>Address: </em>
                {user.address &&
                  user.address.split(',')[user.address.split(',').length - 4]}
                ,
                {user.address &&
                  user.address.split(',')[user.address.split(',').length - 3]}
                ,
                {user.address &&
                  user.address.split(',')[user.address.split(',').length - 2]}
                ,
                {user.address &&
                  user.address.split(',')[user.address.split(',').length - 1]}
                <hr />
                <em>Address Map Location: </em>
                {user.latitude === 'NA' && user.longitude === 'NA' ? (
                  <p>Not Available</p>
                ) : (
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${user.latitude},${user.longitude}`}
                    target='_blank'
                    rel='noreferrer'
                  >
                    {' '}
                    Click Here!{' '}
                  </a>
                )}
              </Alert>
              <Alert key='info' variant='info'>
                INFO: Each item in your order can be made by different Chefs.
                Please contact the correct Chef.
              </Alert>
              <Alert key='success' variant='success'>
                Once the order is ready, please pickup and pay the Chef for that
                order
              </Alert>
            </Toast.Body>
          </Toast>
        </Col>
      </Row>
    </>
  )
}

export default OrderScreen
