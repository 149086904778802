import React, { useState, useEffect } from 'react'
import { Form, Row, Col, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { getUserDetails, updateUserProfile } from '../store/actions/userActions'
import { listChefOrders } from '../store/actions/orderActions'
import GoogleMaps from '../googlemaps/GoogleMaps'

const ProfileChefScreen = () => {
  const [name, setName] = useState('')
  const [permit, setPermit] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')
  const [link, setLink] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [address, setAddress] = useState('')
  const [message, setMessage] = useState(null)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const userDetails = useSelector((state) => state.userDetails)
  const { loading, error, user } = userDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile)
  const { success } = userUpdateProfile

  useEffect(() => {
    if (userInfo && userInfo.isChef) {
      dispatch(listChefOrders(userInfo._id))
      if (latitude && longitude) {
        setLink(
          `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
        )
      }
    } else {
      navigate('/login')
    }
  }, [dispatch, navigate, userInfo, latitude, longitude])

  useEffect(() => {
    if (!userInfo) {
      navigate('/login')
    } else {
      if (!user.name) {
        dispatch(getUserDetails('profile'))
      } else {
        setName(user.name)
        setPermit(user.permit)
        setEmail(user.email)
        setPhone(user.phone)
        setAddress(user.address)
        setLatitude(user.latitude)
        setLongitude(user.longitude)
      }
    }
  }, [navigate, userInfo, user, dispatch])

  const submitHandler = (e) => {
    e.preventDefault()
    //DISPATCH Register
    if (password !== confirmPassword) {
      setMessage('Passwords do not match')
    } else {
      // DISPATCH UPDATE PROFILE
      dispatch(
        updateUserProfile({
          id: user._id,
          name,
          permit,
          email,
          phone,
          address,
          latitude,
          longitude,
          password,
        })
      )
    }
  }

  return (
    <div>
      <center>
        <h2>Chef Profile</h2>
      </center>
      {message && <Message variant='danger'>{message}</Message>}
      {error && <Message variant='danger'>{error}</Message>}
      {success && <Message variant='success'>Profile Updated</Message>}
      {loading && <Loader />}
      <hr />

      <Form onSubmit={submitHandler}>
        <Row>
          <Col>
            <div
              style={{
                margin: '0.5em',
                border: 'solid',
                padding: '1em',
                height: '720px',
              }}
            >
              <Form.Group controlId='name'>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type='name'
                  placeholder='Enter Name'
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <hr />
              <Form.Group controlId='name'>
                <Form.Label>Permit Number</Form.Label>
                <Form.Control
                  type='permit'
                  placeholder='Enter Permit Number'
                  value={permit}
                  onChange={(e) => setPermit(e.target.value)}
                  disabled={true}
                ></Form.Control>
              </Form.Group>
              <hr />
              <Form.Group controlId='email'>
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type='email'
                  placeholder='Enter Email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled={true}
                ></Form.Control>
              </Form.Group>
              <hr />
              <Form.Group controlId='phone'>
                <Form.Label>Contact Number</Form.Label>
                <Form.Control
                  type='phone'
                  placeholder='Enter Contact Number'
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <hr />
              <Form.Group controlId='password'>
                <Form.Label>Change Password</Form.Label>
                <Form.Control
                  type='password'
                  placeholder='Change Password...'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='confirmPassword'>
                <Form.Label>Confirm New Password</Form.Label>
                <Form.Control
                  type='password'
                  placeholder='Confirm New Password...'
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </div>
          </Col>
          <Col>
            <div
              style={{
                margin: '0.5em',
                border: 'solid',
                padding: '1em',
                height: '720px',
              }}
            >
              <Form.Group controlId='address'>
                <Form.Label>Business Address</Form.Label>
                <Form.Control
                  type='address'
                  placeholder='Enter Address'
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <br />
              <Message>
                Format: Street Name with or w/o Apt/Unit#,City,State,ZipCode
              </Message>
              <hr />
              <Form.Group controlId='latitude'>
                <Form.Label>Latitude</Form.Label>
                <Form.Control
                  type='latitude'
                  placeholder='Enter Latitude'
                  value={latitude}
                  onChange={(e) => setLatitude(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <Form.Group controlId='longitude'>
                <Form.Label>Longitude</Form.Label>
                <Form.Control
                  type='longitude'
                  placeholder='Enter Longitude'
                  value={longitude}
                  onChange={(e) => setLongitude(e.target.value)}
                ></Form.Control>
              </Form.Group>
              <br />
              <em>Address Map Location: </em>
              {latitude === 'NA' && longitude === 'NA' ? (
                <p>Not Available</p>
              ) : (
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`}
                  target='_blank'
                  rel='noreferrer'
                >
                  {' '}
                  Click Here!{' '}
                </a>
              )}
              <br />
              <GoogleMaps
                setLatitude={setLatitude}
                setLongitude={setLongitude}
              />
              <br />

              <Message variant='info'>
                NOTE: In case it's not accurate or missing, please update the
                Latitude & Longitude
              </Message>
            </div>
          </Col>
        </Row>
        <hr />
        <center>
          <Button type='submit' variant='primary'>
            Update
          </Button>
        </center>
      </Form>
    </div>
  )
}

export default ProfileChefScreen
