import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router'
import moment from 'moment'
import {
  Button,
  Row,
  Col,
  Image,
  Card,
  ListGroup,
  Alert,
} from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import {
  statusOrder,
  getChefOrderDetails,
  payOrder,
} from '../store/actions/orderActions'
import {
  ORDER_PAY_RESET,
  ORDER_STATUS_RESET,
  ORDER_CHEF_DETAILS_RESET,
} from '../store/types'

const OrderScreen = () => {
  const dispatch = useDispatch()
  const params = useParams()
  const orderId = params.id
  const navigate = useNavigate()

  const [isBtnDisabled, setIsBtnDisabled] = useState(true)

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const orderChefDetails = useSelector((state) => state.orderChefDetails)
  const { order, loading, error } = orderChefDetails

  const orderPay = useSelector((state) => state.orderPay)
  const { loading: loadingPay, success: successPay } = orderPay

  const orderStatus = useSelector((state) => state.orderStatus)
  const { loading: loadingOrderStatus, success: successOrderStatus } =
    orderStatus

  useEffect(() => {
    if (!userInfo) {
      navigate('/login')
    }

    if (!order || order._id !== orderId || successPay || successOrderStatus) {
      dispatch({ type: ORDER_PAY_RESET })
      dispatch({ type: ORDER_STATUS_RESET })
      dispatch({ type: ORDER_CHEF_DETAILS_RESET })
      dispatch(getChefOrderDetails(orderId, userInfo._id))
    }
  }, [
    dispatch,
    orderId,
    order,
    successPay,
    successOrderStatus,
    userInfo,
    navigate,
  ])

  const successPaymentHandler = (id) => {
    dispatch(payOrder(orderId, id))
    setIsBtnDisabled(false)
  }

  const orderStatusHandler = (id) => {
    dispatch(statusOrder(orderId, id))
  }

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant='danger'>{error}</Message>
  ) : (
    <>
      <Row>
        <Link to='/cheforders'>GO BACK</Link>
        <h1>
          <Alert key='info' variant='info'>
            Order No - {order._id}
          </Alert>
        </h1>

        <Col md={8}>
          <ListGroup variant='flush'>
            <ListGroup.Item>
              <h2>Customer Details</h2>
              <strong>Name: </strong>
              {order.userName ? order.userName : order.user.name}
              <br />
              <strong>Email: </strong>
              <a
                href={`mailto:${
                  order.userEmail ? order.userEmail : order.user.email
                }`}
              >
                {order.userEmail ? order.userEmail : order.user.email}
              </a>
              <br />
              <strong>Contact Number: </strong>
              {order.customerPhone}
              <br />
              <strong>User Type: </strong>
              {order.type ? order.type : 'TastyTrails Member'}
            </ListGroup.Item>
            <ListGroup.Item>
              <h2>Order Items</h2>
              {order.orderItems.length === 0 ? (
                <Message>Your cart is empty</Message>
              ) : (
                <ListGroup variant='flush'>
                  {order.orderItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <br />
                      <Row>
                        <Col md={1}>
                          <Image
                            src={item.image}
                            alt={item.name}
                            fluid
                            rounded
                          />
                        </Col>
                        <Col md={4}>
                          <Link to={`/mainpage/product/${item.product}`}>
                            {item.name}
                          </Link>
                        </Col>
                        {/* <Col md={4}>{item.chefId}</Col> */}
                        <Col>
                          {item.qty} x ${item.price} = ${item.qty * item.price}
                        </Col>
                      </Row>
                      <br />
                      <hr />

                      <Row>
                        <Col>
                          <Button
                            variant={
                              item.orderStatus === 'Ready' ? 'dark' : 'success'
                            }
                            onClick={() => orderStatusHandler(item._id)}
                            disabled={item.orderStatus === 'Ready'}
                          >
                            UPDATE ORDER STATUS to "ready"
                          </Button>
                        </Col>
                        <Col md={4}>
                          {item.orderStatus === 'Ready' ? (
                            <Message variant='success'>
                              <center>{item.orderStatus}</center>
                            </Message>
                          ) : (
                            <Message variant='danger'>
                              <center>{item.orderStatus}</center>
                            </Message>
                          )}
                        </Col>
                      </Row>
                      <br />
                      {item.orderStatus === 'Ready' && (
                        <Row>
                          <Col>
                            <Button
                              variant={item.isPaid ? 'dark' : 'success'}
                              onClick={() => successPaymentHandler(item._id)}
                              disabled={item.isPaid}
                            >
                              PAYMENT by customer
                            </Button>
                          </Col>

                          <Col>
                            {item.isPaid ? (
                              <Message variant='success'>
                                <center>
                                  Paid on {moment(item.paidAt).format('llll')}
                                </center>
                              </Message>
                            ) : (
                              <Message variant='danger'>
                                <center>Not Yet Paid</center>
                              </Message>
                            )}
                          </Col>
                        </Row>
                      )}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={4}>
          <Card>
            <ListGroup variant='flush'>
              <ListGroup.Item>
                <h2>
                  <center>Order Summary</center>
                </h2>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <center>
                    <Col>
                      <em>Amount to Get: </em>
                      {'  '}
                      <bold>
                        $
                        {order.orderItems
                          .reduce((acc, item) => acc + item.qty * item.price, 0)
                          .toFixed(2)}
                      </bold>
                    </Col>
                  </center>
                </Row>
              </ListGroup.Item>
              {loadingPay && <Loader />}
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default OrderScreen
