import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import {
  Form,
  Button,
  Row,
  Col,
  Alert,
  Dropdown,
  OverlayTrigger,
} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { register } from '../store/actions/userActions'
import GoogleMaps from '../googlemaps/GoogleMaps'
import foodie from './foodie.jpg'

const RegisterScreen = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isChef, setIsChef] = useState(false)
  const [message, setMessage] = useState(null)
  const [permit, setPermit] = useState('')

  const [authorizedToEarn, setAuthorizedToEarn] = useState('NO')
  const [authorized, setAuthorized] = useState(false)
  const [formVisible, setFormVisible] = useState(false)

  const [streetAddress, setStreetAddress] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [latitude, setLatitude] = useState('NA')
  const [longitude, setLongitude] = useState('NA')

  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [userType, setUserType] = useState('USER TYPE')

  const userRegister = useSelector((state) => state.userRegister)
  const { loading, error, userInfo } = userRegister

  const redirect = location.search ? location.search.split('=')[1] : '/'

  useEffect(() => {
    if (userInfo) {
      navigate(redirect)
    }
  }, [navigate, userInfo, redirect])

  const submitHandler = (e) => {
    e.preventDefault()
    //DISPATCH Register
    if (password !== confirmPassword) {
      setMessage('Passwords do not match')
    } else {
      const addressFoodie = 'NA'
      const permitFoodie = 'NA'
      if (isChef) {
        const completeAddress = `${streetAddress},${city},${state},${zipCode}`
        dispatch(
          register(
            name,
            permit,
            email,
            phone,
            completeAddress,
            latitude,
            longitude,
            password,
            isChef,
            authorized
          )
        )
      } else {
        dispatch(
          register(
            name,
            permitFoodie,
            email,
            phone,
            addressFoodie,
            latitude,
            longitude,
            password,
            isChef,
            authorized
          )
        )
      }
    }
  }

  return (
    <div>
      <Form onSubmit={submitHandler}>
        {message && <Message variant='danger'>{message}</Message>}
        {error && <Message variant='danger'>{error}</Message>}
        {loading && <Loader />}

        {/* <center> */}
        <div>
          <Row>
            <Col style={{ padding: '10px' }}>
              <center>
                <h2>"Sign Up" AS</h2>
              </center>
            </Col>
            <Col>
              <center>
                <i
                  style={{ padding: '30px' }}
                  class='fa-solid fa-circle-arrow-right fa-beat fa-2xl'
                ></i>
              </center>
            </Col>

            <Col style={{ padding: '10px' }}>
              <center>
                <Form.Group>
                  <Dropdown>
                    <Dropdown.Toggle variant='success' id='dropdown-basic'>
                      {userType}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        href='#/action-1'
                        onClick={() => {
                          setIsChef(false)
                          setUserType('FOODIE')
                          setFormVisible(true)
                        }}
                      >
                        Foodie
                      </Dropdown.Item>
                      <hr />
                      <Dropdown.Item
                        href='#/action-2'
                        onClick={() => {
                          setIsChef(true)
                          setUserType('HOME-CHEF / FOOD-TRUCK')
                          setFormVisible(false)
                        }}
                      >
                        HomeChef / FoodTruck
                      </Dropdown.Item>
                      {/* <hr />
                    <Dropdown.Item
                      href='#/action-3'
                      onClick={() => {
                        setIsChef(false)
                        setUserType('BRINGER')
                        setFormVisible(false)
                      }}
                    >
                      Bringer
                    </Dropdown.Item> */}
                    </Dropdown.Menu>
                  </Dropdown>
                </Form.Group>
              </center>
            </Col>
          </Row>
        </div>
        {/* </center> */}

        {isChef && (
          <div>
            <hr />
            <center>
              <Form.Group>
                <Row>
                  <Col md={4}>
                    <center>
                      <Message variant='warning'>
                        AUTHORIZED TO EARN ?{'    '}
                        <OverlayTrigger
                          key='right'
                          placement='top'
                          overlay={
                            <Alert variant='info'>
                              <p>
                                Checking if you are authorized to earn money as
                                a Home Chef or Foodtruck Owner.
                              </p>
                              <p>
                                Choose 'Yes' if you satisfy both the criteria:
                              </p>
                              <li>
                                You are an US Citizen -OR- have a Green Card
                                -OR- have an Authorized Visa to earn Money
                              </li>
                              <li>
                                You have an Authorized Permit / Registration
                                number to start this business
                              </li>
                            </Alert>
                          }
                        >
                          <i class='fa-regular fa-circle-question'></i>
                        </OverlayTrigger>
                      </Message>
                    </center>
                  </Col>
                  <Col>
                    <center>
                      <i
                        style={{ padding: '30px' }}
                        class='fa-solid fa-circle-arrow-right fa-beat fa-2xl'
                      ></i>
                    </center>
                  </Col>
                  <Col>
                    <center>
                      <Dropdown>
                        <Dropdown.Toggle variant='success' id='dropdown-basic'>
                          {authorizedToEarn}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item
                            href='#/action-3'
                            onClick={() => {
                              setAuthorized(true)
                              setAuthorizedToEarn('YES')
                              setFormVisible(true)
                            }}
                          >
                            Yes
                          </Dropdown.Item>
                          <Dropdown.Item
                            href='#/action-4'
                            onClick={() => {
                              setAuthorized(false)
                              setAuthorizedToEarn('NO')
                              setFormVisible(false)
                            }}
                          >
                            No
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </center>
                  </Col>
                </Row>
              </Form.Group>
            </center>
          </div>
        )}
        <hr />
        {userType !== 'USER TYPE' && formVisible && (
          <div>
            <Row>
              <Col
                // style={{
                //   margin: '0.5em',
                //   border: 'solid',
                //   padding: '1em',
                //   height: '900px',
                // }}
                md={4}
              >
                <Form.Group controlId='name'>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type='name'
                    placeholder='Enter Name'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  ></Form.Control>
                </Form.Group>
                <br />
                <Form.Group controlId='email'>
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type='email'
                    placeholder='Enter Email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  ></Form.Control>
                </Form.Group>
                <br />
                <Form.Group controlId='phone'>
                  <Form.Label>Contact Number</Form.Label>
                  <Form.Control
                    type='phone'
                    placeholder='Enter Contact Number'
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                  ></Form.Control>
                </Form.Group>
                <br />
                <Form.Group controlId='password'>
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type='password'
                    placeholder='Enter Password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  ></Form.Control>
                </Form.Group>
                <br />
                <Form.Group controlId='confirmPassword'>
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type='password'
                    placeholder='Confirm Password'
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  ></Form.Control>
                </Form.Group>
                <br />
                <br />
                <hr />
                <br />

                {!isChef && (
                  <center>
                    <Button type='submit' variant='success'>
                      Register " Foodie "
                    </Button>
                  </center>
                )}
                {isChef && (
                  <center>
                    <Button
                      type='submit'
                      variant='success'
                      disabled={!authorized}
                    >
                      Register " Home-Chef / Food-Truck "
                    </Button>
                  </center>
                )}
              </Col>
              {!isChef && (
                <Col>
                  <br />
                  <br />
                  <img src={foodie} alt='foodie' style={{ height: '650px' }} />
                </Col>
              )}

              {isChef && authorizedToEarn === 'YES' && (
                <Col
                  style={{
                    margin: '0.5em',
                    border: 'solid',
                    padding: '1em',
                    height: '800px',
                  }}
                >
                  <Alert key='warning' variant='warning'>
                    <center>
                      For Home Chefs & Food Trucks, a Registration/Permit Number
                      is required.
                      <br />
                      <em>
                        NOTE: Business Address is recommended, even though it is
                        optional
                      </em>
                      <br />
                      {'Home Chefs Registration Info in AZ:  '}
                      <a
                        href='https://www.azdhs.gov/preparedness/epidemiology-disease-control/food-safety-environmental-services/cottage-food-program/index.php'
                        target='_blank'
                        rel='noreferrer'
                      >
                        Click Here!
                      </a>
                    </center>
                  </Alert>

                  <Form.Group controlId='permit'>
                    <Form.Label>Registration / Permit Number</Form.Label>
                    <Form.Control
                      type='permit'
                      placeholder='Enter Registration / Permit Number'
                      value={permit}
                      onChange={(e) => setPermit(e.target.value)}
                      required
                    ></Form.Control>
                  </Form.Group>
                  <br />
                  <Form.Group controlId='staddress'>
                    <Form.Label>
                      Street Address (Optional - Apt/Unit etc.)
                    </Form.Label>
                    <Form.Control
                      type='staddress'
                      placeholder='Your Address'
                      value={streetAddress}
                      onChange={(e) => setStreetAddress(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  <br />
                  <Row>
                    <Col>
                      <Form.Group controlId='city'>
                        <Form.Label>City</Form.Label>
                        <Form.Control
                          type='city'
                          placeholder='Your City'
                          value={city}
                          maxLength={20}
                          onChange={(e) => setCity(e.target.value)}
                          required
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId='state'>
                        <Form.Label>State</Form.Label>
                        <Form.Control
                          type='state'
                          placeholder='Your State'
                          value={state}
                          maxLength={2}
                          onChange={(e) => setState(e.target.value)}
                          required
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId='zipCode'>
                        <Form.Label>Zip Code</Form.Label>
                        <Form.Control
                          type='zipCode'
                          placeholder='Your Zip Code'
                          value={zipCode}
                          maxLength={5}
                          onChange={(e) => setZipCode(e.target.value)}
                          required
                        ></Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                  <br />

                  {latitude !== 'NA' && longitude !== 'NA' && (
                    <Row>
                      <Col>
                        <Form.Group controlId='lat'>
                          <Form.Label>Latitude: </Form.Label>
                          <Form.Control
                            type='latitude'
                            placeholder='Your Location Latitude...'
                            value={latitude}
                            onChange={(e) => setLatitude(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId='lon'>
                          <Form.Label>Longitude: </Form.Label>
                          <Form.Control
                            type='longitude'
                            placeholder='Your Location Longitude...'
                            value={longitude}
                            onChange={(e) => setLongitude(e.target.value)}
                          ></Form.Control>
                        </Form.Group>
                      </Col>
                    </Row>
                  )}

                  <Row>
                    <GoogleMaps
                      setLatitude={setLatitude}
                      setLongitude={setLongitude}
                    />
                  </Row>
                </Col>
              )}
              <br />
              <hr />
            </Row>
          </div>
        )}
      </Form>
      <Row className='py-3'>
        <center>
          <Col>
            Have an Account?{' '}
            <Link to={redirect ? `/login?redirect=${redirect}` : '/login'}>
              Login
            </Link>
          </Col>
        </center>
      </Row>
    </div>
  )
}

export default RegisterScreen
