import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import {
  Form,
  Button,
  Row,
  Col,
  Image,
  Card,
  ListGroup,
  Alert,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import { createGuestOrder } from '../store/actions/orderActions'
import { CART_RESET } from '../store/types'
import { updateProductCountInStock } from '../store/actions/productActions'
import { isEmpty } from 'lodash'

const GuestCheckout = () => {
  const dispatch = useDispatch()
  const cart = useSelector((state) => state.cart)

  const productIds = cart.cartItems.map((item) => item.product)
  const productLength = cart.cartItems.map((item) => item.product).length
  const updatedQty = cart.cartItems.map((item) => item.countInStock - item.qty)

  // Calculate Prices Starts
  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2)
  }
  cart.itemsPrice = addDecimals(
    cart.cartItems.reduce((acc, item) => acc + item.price * item.qty, 0)
  )
  cart.shippingPrice = addDecimals(cart.itemsPrice > 100 ? 0 : 100)
  cart.taxPrice = addDecimals(Number(((5 / 100) * cart.itemsPrice).toFixed(2)))
  cart.totalPrice = addDecimals(
    Number(cart.itemsPrice) + Number(cart.shippingPrice) + Number(cart.taxPrice)
  )

  // Calculate Prices Ends

  const orderCreateGuest = useSelector((state) => state.orderCreateGuest)
  const { order, success, error } = orderCreateGuest

  const navigate = useNavigate()
  useEffect(() => {
    if (success) {
      navigate(`/order/guest/${order._id}`)
    }
    // eslint-disable-next-line
  }, [navigate, success])

  const [name, setName] = useState()
  const [email, setEmail] = useState()
  const [phone, setPhone] = useState()
  const [alert, setAlert] = useState(false)

  const placeOrderHandler = () => {
    if (!isEmpty(name) && !isEmpty(email) && !isEmpty(phone)) {
      dispatch(
        createGuestOrder({
          orderItems: cart.cartItems,
          paymentMethod: 'Cash',
          itemsPrice: cart.itemsPrice,
          shippingPrice: cart.shippingPrice,
          taxPrice: cart.taxPrice,
          totalPrice: cart.itemsPrice,
          customerPhone: phone,
          userType: 'Guest',
          userName: name,
          userEmail: email,
        })
      )
      for (var i = 0; i < productLength; i++) {
        dispatch(updateProductCountInStock(productIds[i], updatedQty[i]))
      }

      dispatch({ type: CART_RESET })
      localStorage.removeItem('cartItems')
      // TO-DO: Fire an action that will go to the product and decrease the countInStock of the product.
    } else {
      setAlert(true)
    }
  }

  return (
    <>
      <Row>
        <Col md={8}>
          <ListGroup variant='flush'>
            <ListGroup.Item>
              <h2>Enter Your Details</h2>
              <Form>
                <Form.Group controlId='name'>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type='name'
                    placeholder='Enter Name *'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId='email'>
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type='email'
                    placeholder='Enter Email *'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId='phone'>
                  <Form.Label>Contact Number</Form.Label>
                  <Form.Control
                    type='phone'
                    placeholder='Enter Contact Number *'
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                  ></Form.Control>
                </Form.Group>
              </Form>
              <br />
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>Payment Method</h2>
              <strong>Method: CASH</strong>
              {cart.paymentMethod}
            </ListGroup.Item>
            <ListGroup.Item>
              <h2>Order Items</h2>
              {cart.cartItems.length === 0 ? (
                <Message>Your cart is empty</Message>
              ) : (
                <ListGroup variant='flush'>
                  {cart.cartItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col md={1}>
                          <Image
                            src={item.image}
                            alt={item.name}
                            fluid
                            rounded
                          />
                        </Col>
                        <Col>
                          <Link to={`/mainpage/product/${item.product}`}>
                            {item.name}
                          </Link>
                        </Col>
                        <Col md={4}>
                          {item.qty} x ${item.price} = ${item.qty * item.price}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
            <ListGroup.Item>
              <h2>Total Price: ${cart.itemsPrice}</h2>
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={4}>
          <Card>
            <ListGroup variant='flush'>
              <ListGroup.Item>
                <h2>
                  <center>Order Summary</center>
                </h2>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Total Price: </Col>
                  <Col>${cart.itemsPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                {error && <Message variant='danger'>{error}</Message>}
              </ListGroup.Item>
              <ListGroup.Item>
                <center>
                  <Button
                    type='button'
                    className='btn-block'
                    disabled={cart.cartItems.length === 0}
                    onClick={placeOrderHandler}
                  >
                    Place Order
                  </Button>
                </center>
              </ListGroup.Item>
            </ListGroup>
            {alert && (
              <Message variant='danger'>
                Please enter your name, email and phone number!
              </Message>
            )}
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default GuestCheckout
