import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import {
  ListGroup,
  Table,
  Button,
  Form,
  Row,
  Col,
  Modal,
} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Product from '../components/Product'
import FormContainer from '../components/FormContainer'
import { listUsers } from '../store/actions/userActions'
import { listProductsOfChef } from '../store/actions/productActions.js'

const ChefList = () => {
  const dispatch = useDispatch()
  const [zipCode, setZipCode] = useState(null)
  const [zipError, setZipError] = useState(null)
  const [zipCodeResult, setZipCodeResult] = useState()
  // For Modal - Food Items
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = (id) => {
    setShow(true)
    dispatch(listProductsOfChef(id))
  }

  const userList = useSelector((state) => state.userList)
  const { loading, error, users } = userList

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const searchZipCodeHandler = async () => {
    if (zipCode !== null) {
      const response = await axios.get(
        `https://api.zip-codes.com/ZipCodesAPI.svc/1.0/QuickGetZipCodeDetails/${zipCode}?key=CHY9WDZHN373DOGEKVR1`
      )
      const resp = await response.data
      if (resp.Error) {
        setZipError(resp.Error)
      } else {
        setZipError(null)
      }
      if (resp.City) {
        setZipCodeResult(resp.City)
      } else {
        setZipCodeResult('')
      }
    }
  }

  const productListChef = useSelector((state) => state.productListChef)
  const { loading: loadingPLC, error: errorPLC, productsChef } = productListChef

  useEffect(() => {
    if (userInfo) {
      dispatch(listUsers())
    }
  }, [dispatch, userInfo])

  return (
    <ListGroup>
      <ListGroup.Item>
        <br />
        <center>
          <h2>SEARCH - 'HOME-CHEFS' & 'FOOD-TRUCKS' USING YOUR - 'ZIPCODE'</h2>
        </center>
        {!userInfo.isChef ? (
          <>
            <FormContainer>
              {error && <Message variant='danger'>{error}</Message>}
              {loading && <Loader />}
              <br />
              <Form>
                <Form.Group controlId='ordernumber'>
                  <Form.Control
                    type='ordernumber'
                    placeholder='Enter Your ZipCode, Find Home-Chefs & Food-Trucks Near You...'
                    value={zipCode}
                    onChange={(e) => setZipCode(e.target.value)}
                    required
                  ></Form.Control>
                </Form.Group>
                <br />
                <center>
                  <Button
                    className='btn-md'
                    variant='success'
                    onClick={searchZipCodeHandler}
                  >
                    Search
                  </Button>
                </center>
              </Form>
            </FormContainer>
            <br />
            <center>
              {zipError !== null && (
                <Message variant='info'>{zipError}</Message>
              )}
            </center>
            <hr />
            <br />
            {loading ? (
              <Loader />
            ) : error ? (
              <Message variant='danger'>{error}</Message>
            ) : (
              <Table striped bordered hover responsive className='table-sm'>
                <thead>
                  <tr>
                    <th>NAME</th>
                    <th>EMAIL</th>
                    <th>PHONE</th>
                    <th>STREET ADDRESS</th>
                    <th>CITY</th>
                    <th>STATE</th>
                    <th>ZIP</th>
                    <th>LOCATION</th>
                    <th>FOOD ITEMS</th>
                  </tr>
                </thead>
                <tbody>
                  {users
                    .filter(
                      (user) =>
                        user.isChef === true &&
                        user.address
                          .split(',')
                          [user.address.split(',').length - 3].toUpperCase() ===
                          (zipCodeResult || 'PHOENIX')
                    )
                    .map((user) => (
                      <tr key={user._id}>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.phone}</td>
                        <td>
                          {user.address &&
                            user.address.split(',')[
                              user.address.split(',').length - 4
                            ]}
                        </td>
                        <td>
                          {user.address &&
                            user.address.split(',')[
                              user.address.split(',').length - 3
                            ]}
                        </td>
                        <td>
                          {user.address &&
                            user.address.split(',')[
                              user.address.split(',').length - 2
                            ]}
                        </td>
                        <td>
                          {user.address &&
                            user.address.split(',')[
                              user.address.split(',').length - 1
                            ]}
                        </td>
                        <td>
                          {user.latitude === 'NA' && user.longitude === 'NA' ? (
                            <Button>Not Available</Button>
                          ) : (
                            <a
                              href={`https://www.google.com/maps/search/?api=1&query=${user.latitude},${user.longitude}`}
                              target='_blank'
                              rel='noreferrer'
                            >
                              <Button variant='info'>Click Here!</Button>
                            </a>
                          )}
                        </td>
                        <td>
                          <div>
                            <Button
                              variant='primary'
                              onClick={() => handleShow(user._id)}
                            >
                              CLICK HERE
                            </Button>
                            <Modal size='lg' show={show} onHide={handleClose}>
                              <Modal.Header closeButton>
                                <Modal.Title>FOOD ITEMS</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <center>
                                  <Message variant='success'>
                                    Latest Cuisines! -{' '}
                                    {moment(Date.now()).format('LLLL')}
                                  </Message>
                                </center>
                                <br />
                                <hr />
                                {loadingPLC ? (
                                  <Loader />
                                ) : errorPLC ? (
                                  <Message variant='danger'>{error}</Message>
                                ) : productsChef.length > 0 ? (
                                  <Row>
                                    {productsChef.map((product) => (
                                      <div style={{ display: 'grid' }}>
                                        <Col
                                          key={product._id}
                                          sm={12}
                                          md={6}
                                          lg={4}
                                          xl={3}
                                        >
                                          <Product product={product} />
                                        </Col>
                                      </div>
                                    ))}
                                  </Row>
                                ) : (
                                  <Message>
                                    <center>
                                      NO FOOD ITEMS FROM THIS CHEF PRESENTLY
                                    </center>
                                  </Message>
                                )}
                              </Modal.Body>
                            </Modal>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            )}
          </>
        ) : userInfo.isChef ? (
          <Message>Only the Foodie's can view this page. Sorry!</Message>
        ) : (
          <Message>
            Please <Link to='/login'>Sign In</Link> to view all the Chefs along
            with their Locations
          </Message>
        )}
      </ListGroup.Item>
    </ListGroup>
  )
}

export default ChefList
