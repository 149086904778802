import React from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap'
import { logout } from '../store/actions/userActions'

const Header = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const cart = useSelector((state) => state.cart)
  const { cartItems } = cart
  const logoutHandler = () => {
    dispatch(logout())
    navigate('/')
  }

  return (
    <header>
      <Navbar bg='dark' variant='dark' expand='lg' collapseOnSelect>
        <Container>
          <LinkContainer to='/'>
            <Navbar.Brand href='/'>tasty trails</Navbar.Brand>
          </LinkContainer>

          {!userInfo && (
            <Nav className='ms-auto'>
              <LinkContainer to='/search'>
                <Nav.Link>
                  <i className='fa-solid fa-magnifying-glass fa-beat'></i> GUEST
                  ORDER
                </Nav.Link>
              </LinkContainer>
            </Nav>
          )}

          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='ms-auto'>
              {cartItems.length > 0 ? (
                <LinkContainer to='/cart'>
                  <Nav.Link>
                    <i className='fas fa-shopping-cart'></i>Cart
                  </Nav.Link>
                </LinkContainer>
              ) : (
                ''
              )}
              {userInfo && !userInfo.isChef && (
                <LinkContainer to='/cheflist'>
                  <Nav.Link>
                    <i className='fas fa-search fa-beat'></i> CHEFS
                  </Nav.Link>
                </LinkContainer>
              )}
              {userInfo && userInfo.isAdmin && (
                <NavDropdown title='Admin' id='adminmenu'>
                  <LinkContainer to='/admin/userlist'>
                    <NavDropdown.Item>Users</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/productlist'>
                    <NavDropdown.Item>Products</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/orderlist'>
                    <NavDropdown.Item>Orders</NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              )}
              {/* {userInfo && userInfo.isChef && (
                <NavDropdown title='Chef' id='chefmenu'>
                  <LinkContainer to='/chef/productlist'>
                    <NavDropdown.Item>Add Food Items</NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              )} */}
              {userInfo ? (
                <NavDropdown title={userInfo.name} id='username'>
                  {userInfo.isChef ? (
                    <LinkContainer to='/chefprofile'>
                      <NavDropdown.Item>Profile</NavDropdown.Item>
                    </LinkContainer>
                  ) : (
                    <LinkContainer to='/profile'>
                      <NavDropdown.Item>Profile</NavDropdown.Item>
                    </LinkContainer>
                  )}
                  {userInfo.isChef && (
                    <LinkContainer to='/cheforders'>
                      <NavDropdown.Item>My Orders</NavDropdown.Item>
                    </LinkContainer>
                  )}
                  {userInfo.isChef && (
                    <LinkContainer to='/chef/productlist'>
                      <NavDropdown.Item>Add Food Items</NavDropdown.Item>
                    </LinkContainer>
                  )}
                  <NavDropdown.Item onClick={logoutHandler}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <div style={{ display: 'flex' }}>
                  <LinkContainer to='/login'>
                    <Nav.Link>
                      <i className='fas fa-user fa-beat'></i> Login
                    </Nav.Link>
                  </LinkContainer>
                  <LinkContainer to='/register'>
                    <Nav.Link>
                      <i className='fas fa-user fa-beat'></i> Sign Up
                    </Nav.Link>
                  </LinkContainer>
                </div>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  )
}

export default Header
