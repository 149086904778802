import { useState } from 'react'
import { Button, OverlayTrigger, Alert } from 'react-bootstrap'
import Loader from '../components/Loader'
import Message from '../components/Message'
import './map.css'

const GoogleMaps = ({ setLatitude, setLongitude }) => {
  let latitude
  let longitude
  const [link, setLink] = useState('')
  const [lat, setLat] = useState('')
  const [long, setLong] = useState('')
  const [errorMsg, setErrorMsg] = useState(false)
  const [loading, setLoading] = useState(false)

  const success = (position) => {
    latitude = position.coords.latitude
    longitude = position.coords.longitude
    setLat(latitude)
    setLong(longitude)
    setLatitude(latitude)
    setLongitude(longitude)
    setLink(
      `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`
    )
    setErrorMsg(false)
    setLoading(false)
    // https://www.google.com/maps/place/8627+W+Hayward+Ave,+Glendale,+AZ+85305
  }

  function error() {
    setErrorMsg(true)
  }

  const handleClick = () => {
    setLoading(true)
    navigator.geolocation.getCurrentPosition(success, error)
  }

  return (
    <div className='map'>
      <br />
      <center>
        {/* https://developer.mozilla.org/en-US/docs/Web/API/Geolocation_API/Using_the_Geolocation_API */}
        <Button variant='success' onClick={handleClick}>
          {'GET MY location !  '}
          <OverlayTrigger
            key='right'
            placement='top'
            overlay={
              <Alert variant='info'>
                <center>
                  'GET MY LOCATION' is Optional, but can help in locating you
                  for other Users.
                  <br />
                  So, it's recommended you click on the button.
                  <br />
                  You can also add it later in your 'My Profile' page after you
                  Sign Up!
                  <br />
                  NOTE: Make sure Location is turned on for your browser
                </center>
              </Alert>
            }
          >
            <i class='fa-regular fa-circle-question'></i>
          </OverlayTrigger>
        </Button>

        <br />
        <br />
        {!errorMsg && lat && long && link && (
          <Message>
            <p>
              Your Current Location for your Review:
              <a href={link} target='_blank' rel='noreferrer'>
                {' '}
                Click Here!{' '}
              </a>
              <p>
                <strong>
                  NOTE: Please make sure that position in the map is accurate!
                </strong>
              </p>
              <p>
                <strong>
                  In case you feel it can be more accurate, please copy the
                  Latitude & Longitude
                  <br />
                  from Google Maps and update the values above
                </strong>
              </p>
            </p>
          </Message>
        )}
        {}
        {errorMsg && <p>Unable to retrieve your location</p>}
        {loading && <Loader />}
      </center>
    </div>
  )
}

export default GoogleMaps
