import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Form, Button, Alert, Col, Image } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { getGuestOrderDetails } from '../store/actions/orderActions'
import { ORDER_GUEST_DETAILS_RESET } from '../store/types'

const OrderSearch = () => {
  const [orderNumber, setOrderNumber] = useState('')
  const [message, setMessage] = useState(null)
  const [display, setDisplay] = useState(false)

  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const orderGuestDetails = useSelector((state) => state.orderGuestDetails)
  const { order, loading, success, error } = orderGuestDetails

  const redirect = location.search ? location.search.split('=')[1] : '/'

  const submitHandler = () => {
    //DISPATCH getOrder
    dispatch(getGuestOrderDetails(orderNumber))
    setDisplay(true)
  }

  useEffect(() => {
    if (success && display && order.type === 'Guest') {
      navigate(`/order/guest/${order._id}`)
    } else {
      dispatch({ type: ORDER_GUEST_DETAILS_RESET })
      // setDisplay(false)
      navigate('/search')
    }
    // eslint-disable-next-line
  }, [navigate, success])

  return (
    <div>
      <center>
        <h1>Search Your Order</h1>
      </center>
      <FormContainer>
        {message && <Message variant='danger'>{message}</Message>}
        {error && <Message variant='danger'>{error}</Message>}
        {loading && <Loader />}
        <br />
        <Form>
          <Form.Group controlId='ordernumber'>
            <Form.Control
              type='ordernumber'
              placeholder='Search Guest Order Number...'
              value={orderNumber}
              onChange={(e) => setOrderNumber(e.target.value)}
              required
            ></Form.Control>
          </Form.Group>
          <br />
          <center>
            <Button
              className='btn-md'
              variant='success'
              onClick={submitHandler}
            >
              Search
            </Button>
          </center>
        </Form>
      </FormContainer>
      <br />

      {display && (
        <center>
          <Alert variant='info'>
            INFO*: Order Search Page is only for Guest members. For members,
            please login for more details on your Order
          </Alert>
        </center>
      )}

      <center>
        <Col className='py-3'>
          Are you a member?{' '}
          <Link to={redirect ? `/login?redirect=${redirect}` : '/login'}>
            Login Here
          </Link>
        </Col>
      </center>
    </div>
  )
}

export default OrderSearch
