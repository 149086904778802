import {
  ORDER_CHEF_DETAILS_FAIL,
  ORDER_CHEF_DETAILS_REQUEST,
  ORDER_CHEF_DETAILS_RESET,
  ORDER_CHEF_DETAILS_SUCCESS,
  ORDER_CHEF_LIST_FAIL,
  ORDER_CHEF_LIST_REQUEST,
  ORDER_CHEF_LIST_SUCCESS,
  ORDER_CREATE_FAIL,
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_DELETE_FAIL,
  ORDER_DELETE_REQUEST,
  ORDER_DELETE_SUCCESS,
  ORDER_DELIVER_FAIL,
  ORDER_DELIVER_REQUEST,
  ORDER_DELIVER_RESET,
  ORDER_DELIVER_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_GUEST_CREATE_FAIL,
  ORDER_GUEST_CREATE_REQUEST,
  ORDER_GUEST_CREATE_SUCCESS,
  ORDER_GUEST_DETAILS_FAIL,
  ORDER_GUEST_DETAILS_REQUEST,
  ORDER_GUEST_DETAILS_RESET,
  ORDER_GUEST_DETAILS_SUCCESS,
  ORDER_LIST_FAIL,
  ORDER_LIST_REQUEST,
  ORDER_LIST_SUCCESS,
  ORDER_MY_LIST_FAIL,
  ORDER_MY_LIST_REQUEST,
  ORDER_MY_LIST_RESET,
  ORDER_MY_LIST_SUCCESS,
  ORDER_PAY_FAIL,
  ORDER_PAY_REQUEST,
  ORDER_PAY_RESET,
  ORDER_PAY_SUCCESS,
  ORDER_STATUS_FAIL,
  ORDER_STATUS_REQUEST,
  ORDER_STATUS_RESET,
  ORDER_STATUS_SUCCESS,
} from '../types'

export const orderCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_CREATE_REQUEST:
      return { loading: true }
    case ORDER_CREATE_SUCCESS:
      return { loading: false, success: true, order: action.payload }
    case ORDER_CREATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const orderCreateGuestReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_GUEST_CREATE_REQUEST:
      return { loading: true }
    case ORDER_GUEST_CREATE_SUCCESS:
      return { loading: false, success: true, order: action.payload }
    case ORDER_GUEST_CREATE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const orderDetailsReducer = (
  state = { loading: true, orderItems: [], shippingAddress: {} },
  action
) => {
  switch (action.type) {
    case ORDER_DETAILS_REQUEST:
      return { ...state, loading: true }
    case ORDER_DETAILS_SUCCESS:
      return { loading: false, order: action.payload }
    case ORDER_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const orderGuestDetailsReducer = (
  state = { loading: true, orderItems: [] },
  action
) => {
  switch (action.type) {
    case ORDER_GUEST_DETAILS_REQUEST:
      return { ...state, loading: true }
    case ORDER_GUEST_DETAILS_SUCCESS:
      return { loading: false, success: true, order: action.payload }
    case ORDER_GUEST_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    case ORDER_GUEST_DETAILS_RESET:
      return { loading: false, orderItems: [], success: false }
    default:
      return state
  }
}

export const orderChefDetailsReducer = (
  state = { loading: true, orderItems: [], shippingAddress: {} },
  action
) => {
  switch (action.type) {
    case ORDER_CHEF_DETAILS_REQUEST:
      return { ...state, loading: true }
    case ORDER_CHEF_DETAILS_SUCCESS:
      return { loading: false, order: action.payload }
    case ORDER_CHEF_DETAILS_FAIL:
      return { loading: false, error: action.payload }
    case ORDER_CHEF_DETAILS_RESET:
      return { loading: true, orderItems: [], shippingAddress: {} }
    default:
      return state
  }
}

export const orderPayReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_PAY_REQUEST:
      return { loading: true }
    case ORDER_PAY_SUCCESS:
      return { loading: false, success: true }
    case ORDER_PAY_FAIL:
      return { loading: false, error: action.payload }
    case ORDER_PAY_RESET:
      return {}
    default:
      return state
  }
}

export const orderStatusReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_STATUS_REQUEST:
      return { loading: true }
    case ORDER_STATUS_SUCCESS:
      return { loading: false, success: true }
    case ORDER_STATUS_FAIL:
      return { loading: false, error: action.payload }
    case ORDER_STATUS_RESET:
      return {}
    default:
      return state
  }
}

export const orderDeliverReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_DELIVER_REQUEST:
      return { loading: true }
    case ORDER_DELIVER_SUCCESS:
      return { loading: false, success: true }
    case ORDER_DELIVER_FAIL:
      return { loading: false, error: action.payload }
    case ORDER_DELIVER_RESET:
      return {}
    default:
      return state
  }
}

export const orderMyListReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case ORDER_MY_LIST_REQUEST:
      return { loading: true }
    case ORDER_MY_LIST_SUCCESS:
      return { loading: false, orders: action.payload }
    case ORDER_MY_LIST_FAIL:
      return { loading: false, error: action.payload }
    case ORDER_MY_LIST_RESET:
      return { orders: [] }
    default:
      return state
  }
}

export const orderChefListReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case ORDER_CHEF_LIST_REQUEST:
      return { loading: true }
    case ORDER_CHEF_LIST_SUCCESS:
      return { loading: false, orders: action.payload }
    case ORDER_CHEF_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const orderListReducer = (state = { orders: [] }, action) => {
  switch (action.type) {
    case ORDER_LIST_REQUEST:
      return { loading: true }
    case ORDER_LIST_SUCCESS:
      return { loading: false, orders: action.payload }
    case ORDER_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const orderDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case ORDER_DELETE_REQUEST:
      return { loading: true }
    case ORDER_DELETE_SUCCESS:
      return { loading: false, success: true }
    case ORDER_DELETE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
